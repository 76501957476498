import React, { Component } from 'react'
import Identicon from 'identicon.js'
import LMCLogo from '../LMC_logo.png'

class Navbar extends Component {

  
 
  render() {
    return (

        <nav className="ww-navbar ww-fontface navbar navbar-dark fixed-top flex-md-nowrap ">
          <a
            className="ww-logo ww-fontface navbar-brand col-sm-3 col-md-2 mr-0"
            style={{"padding": "10px", "marginRight": "10px", "marginTop": "10px"}}
            href="https://littlemutants.io/"
                        target="_blank"
rel="noopener noreferrer"
          >
          <img src={LMCLogo} style={{"height": "65px", "width": "65px"}} className="ww-logo"/>
          </a>

          <ul className="ww-fontface navbar-nav px-3">
          <li className="ww-fontface nav-item text-nowrap d-none d-sm-none d-sm-block">
            <small style={{"color": "#FAF9F6"}} className="ww-fontface">
              <small id="account">{this.props.account}</small>
            </small>

            { this.props.account
              ? 
              <a onClick={this.props.switchWallet}>
              <img
                className="ww-fontface ml-2"
                width='30'
                height='30'
                src={`data:image/png;base64,${new Identicon(this.props.account, 30).toString()}`}
                alt=""
              />
              </a>
              : <span></span>
            }

          </li>
        </ul>
        </nav>

    );
  }
}

export default Navbar;
