import React, { Component } from 'react'
import tokenLogo from '../LMC_logo.png'
import ethLogo from '../eth-logo.png'
import mainLogo from '../LMC_Main_Logo.jpg'


class BuyForm extends Component {

  constructor(props){
    super(props)
    this.state = {
      output: '0',
      exceedsMax: false
    }
  }

  
  render() {
   
    return (
        <div style={{padding:'20px', display:'flex', flexDirection:'column', 'alignItems': 'center'}}>
        <img src={mainLogo} style={{height:'40vh', width:'40vh', marginBottom:'2vh'}}/>
        <form className="ww-fontface mb-3" onSubmit={(event) => {
            event.preventDefault()
            let amount
            amount = parseInt(this.input.value)
            this.props.buyTokens(amount)
                  }}>
        <div>
          <label style={{color:'white'}} className="ww-fontface float-left"><b>ENTER AMOUNT</b></label>
          <label style={{color:'white'}} className="ww-fontface float-right">
             BALANCE: {this.props.tokenBalance}
          </label>
        </div>
        <div className="ww-fontface input-group mb-4">
          <input 
          onChange={(event) => {
      
            //console.log('changing')   
            const amount = Number(this.input.value.toString())
            if (parseInt(amount) > 1000 || parseInt(amount) < 1 || !Number.isInteger(amount)) {
              this.setState({ exceedsMax: true })
            } else {
              this.setState({ exceedsMax: false })
            }
            this.setState({
              output : Number(parseFloat(parseInt(amount) * 0.025).toPrecision(2))
            })
          
                  }}
            ref={(input) =>  {this.input = input}}
            type="text"
            style={{color:'white', backgroundColor:'rgba(49, 54, 57, 0.4)', border:'none'}}
            className="ww-fontface form-control form-control-lg"
            placeholder="0"
            required />
          <div className="ww-fontface input-group-append">
            <div style={{color:'white', backgroundColor:'rgba(49, 54, 57, 0.4)', border:'none'}} className="ww-fontface input-group-text">
              <img src={tokenLogo} height='32' width='32' alt=""/>
              &nbsp;&nbsp; LMC
            </div>
          </div>
        </div>
        <div>
          <label style={{color:'white'}} className="ww-fontface float-left"><b>COST</b></label>
          <label style={{color:'white'}} className="ww-fontface float-right">
            BALANCE: {Number(parseFloat(window.web3.utils.fromWei(this.props.etherBalance,'Ether')).toPrecision(3))}
               </label >
        </div>
        <div className="ww-fontface input-group mb-2">
          <input
            type="text"
            style={{color:'white', backgroundColor:'rgba(49, 54, 57, 0.4)', border:'none'}}
            className="ww-fontface form-control form-control-lg"
            placeholder="ETH Amount"
          value= {this.state.output}   
            disabled
          />
          <div className="ww-fontface input-group-append">
            <div style={{color:'white', backgroundColor:'rgba(49, 54, 57, 0.4)', border:'none'}} className="ww-fontface input-group-text">
              <img src={ethLogo} height='32' width='32' alt=""/>
              &nbsp;&nbsp;&nbsp; ETH
            </div>
          </div>
        </div>
        {!this.state.exceedsMax ?
          <button style={{border:'3px solid #FFFFFF', backgroundColor:'#000000', marginTop:'3vh'}} type="submit" className="ww-fontface btn btn-primary btn-block btn-lg">MINT MUTANTS</button>
          :
          <button style={{border:'3px solid #FFFFFF', backgroundColor:'#000000', marginTop:'3vh'}} disabled className="ww-fontface btn btn-primary btn-block btn-lg">INVALID AMOUNT</button>
        }
        <div style={{marginTop:'2vh'}} className="ww-fontface mb-5">
          <label style={{color:'white'}} className="ww-fontface float-middle">{this.props.tokenSupply + '/5000 MINTED'}</label>
        </div>
      </form>
      </div>
      
    );
  }
}

export default BuyForm;