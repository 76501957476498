import React, { Component } from 'react'
import Web3 from 'web3'
import lmc from '../abis/lmc.json'
import pin from '../abis/pin.json'
import wl from '../abis/wl.json'
import background from '../background.png'
import Navbar from './Navbar'
import Main from './Main'
import './App.css'
import Onboard from 'bnc-onboard'
import Swal from 'sweetalert2'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingOverlay from 'react-loading-overlay';
import mainLogo from '../LMC_Main_Logo.jpg';
const axios = require('axios');



// let web3;
const wallets = [
  { walletName: 'metamask', preferred:true},
  { walletName: "coinbase", preferred: true },
  { walletName: "trust", preferred: true, rpcUrl: pin.mn },
  {
    walletName: "walletConnect",
    infuraKey: pin.pj,
    preferred:true
  },
  { walletName: "authereum" },
  { walletName: "operaTouch" },
  { walletName: "torus" }
]

//change for mainnet
const onboard = Onboard({
  networkId: 1,  // [Integer] The Ethereum network ID your Dapp uses.
  darkMode: true,
  walletSelect: {
     wallets: wallets 
  },
  subscriptions: {
    wallet: wallet => {
       window.web3 = new Web3(wallet.provider)
    }
  }
});

// //change for mainnet
// const onboard = Onboard({
//   networkId: 1,  // [Integer] The Ethereum network ID your Dapp uses.
//   darkMode: true,
//   subscriptions: {
//     wallet: wallet => {
//        window.web3 = new Web3(wallet.provider)
//     }
//   }
// });

toast.configure()

class App extends Component {

  async componentWillMount() {
    const status = await this.loadWeb3()
    if (status == true){
      await this.loadBlockchainData()
    }
  }

  async loadBlockchainData() {
    const web3 = window.web3;

    const accounts = await web3.eth.getAccounts()
    if (!wl.some(item => item.toLowerCase() === accounts[0].toLowerCase())) {
      // this.setState({ loading: false })
      this.setState({ whitelisted: false })
    }

    this.setState({ account: accounts[0] })
      this.setState({ loading: false })


    const etherBalance = await web3.eth.getBalance(this.state.account)
    this.setState({ etherBalance })

    // Load Token
    const token = new web3.eth.Contract(lmc.abi, lmc.address)
    this.setState({ token })
    let tokenBalance = await token.methods.balanceOf(this.state.account).call()
    this.setState({ tokenBalance: tokenBalance.toString() })
    let tokenSupply = await token.methods.totalSupply().call()
    this.setState({ tokenSupply: tokenSupply.toString() })
    let wlSaleIsPaused = await token.methods.paused().call()
    this.setState({ wlSaleIsActive: !wlSaleIsPaused })
    let mintSaleIsActive = await token.methods.getPublicSaleIsActive().call()
    this.setState({ mintSaleIsActive: mintSaleIsActive })

    const currPriceWei = await token.methods.getMintPrice().call()
    const currPrice = currPriceWei/(10**18)
    this.setState({currPriceWei})
    this.setState({currPrice})

    // this.setState({ loading: false })
    this.setState({ apiSuccess: false })
  }

  async loadWeb3() {
    this.setState({loading:true})
    this.setState({whitelisted:true})
    const walletSelected = await onboard.walletSelect();
    if (walletSelected == true) {
      const walletReady = await onboard.walletCheck();
      return walletReady
    }
    return false
  }

  switchWallet = () => {
    onboard.walletReset();
    this.loadWeb3().then(result => {
      if (result) {
        this.loadBlockchainData()
      }
    })
  }


  buyTokens = (amount) => {
    this.setState({ loading: true })
    this.setState({ apiSuccess: false })
    if (amount < 1) {
      window.alert('Enter amount greater than 0')
    }

    const currPriceWei = this.state.currPriceWei
    const currPrice = this.state.currPrice

    //check if ether balance is enough
    const etherAmountWei = amount * currPriceWei
    const etherAmount = etherAmountWei/(10**18)
    if (etherAmountWei > parseInt(this.state.etherBalance)) {
      window.alert('Insufficient Ether Balance')
    }

    //mint token WL
    this.setState({minting: true})
    if (this.state.wlSaleIsActive && !this.state.mintSaleIsActive) {
      //sign
      var hash = Web3.utils.soliditySha3({t: 'address', v: this.state.account})
      let jwt = pin.nta
      let signature = window.web3.eth.accounts.sign(hash, jwt);
      this.state.token.methods.mintWL(amount, signature.signature).send({from:this.state.account, value:etherAmountWei}).then(res => this.showConfirmation(res)).catch(err => this.setState({minting:false}))
    } else if (this.state.mintSaleIsActive) {
      this.state.token.methods.mint(amount).send({from:this.state.account, value:etherAmountWei}).then(res => this.showConfirmation(res)).catch(err => this.setState({minting:false}))
    }
      

    this.setState({loading: false})
  }

  showConfirmation = (res) => {  
    this.setState({minting: false})
    Swal.fire({
        text: 'Success! Check your OpenSea to view your mutants.',
        timer: 20000,
        timerProgressBar: true,
        confirmButtonText: `View Txn Hash`
      }).then((result) => {  
          if (result.isConfirmed) {    
            this.openInNewTab(res.transactionHash);
          }
          this.loadBlockchainData();
      })
  }

  modalClose = () => {
    this.setState({apiSuccess: false})
  }

  //change for mainnet
  openInNewTab = (hash) => {
    window.open('https://etherscan.io/tx/'+hash, '_blank');
    // window.open('https://opensea.io/' + this.state.account);
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      token: {},
      etherSwap: {}, 
      etherBalance: '0',
      tokenBalance: '0',
      salt: 0,
      signature: '',
      loading: true,
      apiSuccess: false,
      minting: false
    }
  }

  render() {
    let content

    if(this.state.loading) {
     content = <><p id="loader" className="ww-fontface text-center" style={{"color":'white', "marginTop": "13vh"}}>CONNECT WALLET</p><div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
      </div></>
    } 
    else if (!this.state.mintSaleIsActive && !this.state.whitelisted) {
      content = <><p id="loader" className="ww-fontface text-center" style={{"color":'white', "margin-top": "13vh"}}>WALLET NOT WHITELISTED. PLEASE RETRY DURING PUBLIC MINT.</p><div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
      </div></>
    } else if (!this.state.wlSaleIsActive && this.state.whitelisted) {
      content = <><p id="loader" className="ww-fontface text-center" style={{"color":'white', "margin-top": "13vh"}}>CONGRATULATIONS. YOU ARE WHITELISTED TO MINT MUTANTS.</p><div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
      }}>
      <img src={mainLogo} style={{height:'40vh', width:'40vh', marginTop:'2vh'}}/>
      </div></>
    } else {
      content = <Main 
        etherBalance={this.state.etherBalance}
        tokenBalance={this.state.tokenBalance}
        tokenSupply={this.state.tokenSupply}
        buyTokens={this.buyTokens}
      />
    }




    return (

      <React.Fragment>
      <LoadingOverlay
        className="ww-fontface"
        active={this.state.minting}
        spinner
        text='MINTING YOUR MUTANT(S)...'
      >
      <div className="background-div" style={{display:'flex', justifyContent:'center', height:"100vh", width:"100vw" }}>
        <Navbar account={this.state.account} switchWallet={this.switchWallet} />
        <div className="ww-fontface container-fluid mt-5">
          <div className="ww-fontface row">
            <main role="main" className="ww-fontface col-lg-12 ml-auto mr-auto" style={{ maxWidth: '600px' }}>
              <div className="ww-fontface content mr-auto ml-auto">
                {content}
              </div>
            </main>
          </div>
        </div>
      </div>
      </LoadingOverlay>
      </React.Fragment>
     
 

    );
  }
}

export default App;