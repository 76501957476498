import React, { Component } from 'react'
import BuyForm from './BuyForm'

class Main extends Component {

  constructor(props){
super(props)
this.state ={
  currentForm: 'buy'
}
}
    
    render() {
      let content
      content = <BuyForm
        etherBalance={this.props.etherBalance}
        tokenBalance={this.props.tokenBalance}
        tokenSupply={this.props.tokenSupply}
        buyTokens={this.props.buyTokens}
      />
      return (
          <React.Fragment>
          <div style={{minHeight: "100px"}}/>
          {content}
          </React.Fragment>
        )
      
    }

}

export default Main;